import cx from "classnames";
import { graphql } from "gatsby";
import React, { FC } from "react";
import { LinkCta } from "../components/Cta";
import { GuideCard } from "../components/guideCard";
import { Newsletter } from "../components/newsletter";
import { sendGA } from "../utils/helpers";

import SEO from "../components/seo";

import * as global from "../styles/global.module.scss";
import * as styles from "../styles/pages/les-guides-de-marques-ethiques.module.scss";

const { routes } = require("../routes");
interface Props {
  data: {
    [key: string]: any;
  };
  location: Location;
}

export const LesGuidesDesMarquesEthiquesQuery = graphql`
  query {
    allStrapiProduct(
      filter: { type: { eq: "guide" }, status: { eq: "published" } }
    ) {
      edges {
        node {
          id
          slug
          title
          description
          SKU
          fileguid
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;

const LesGuidesDesMarquesEthiques: FC<Props> = (props: Props) => {
  const { data, location } = props;
  const strapiGuides = data.allStrapiProduct.edges;

  return (
    <>
      <SEO
        meta={[
          {
            name: "og:url",
            content: location.href,
          },
        ]}
        title="Constant - assistant shopping 100% éco-responsable"
        description="Personal shopper 100% éthique. Trouvez les vetements que vous cherchez, en version éco-responsable."
      />
      <div className={cx(global.pageWrapper)}>
        <div className={cx(global.intro)}>
          <p className={cx(global.text)}>
            <strong>
              Personal shopper 100% éthique.
              <br />
              Trouvez les vetements que vous cherchez, en version
              éco-responsable.
            </strong>
          </p>
        </div>

        <div className={styles.listContainer}>
          <ul className={styles.guidesList}>
            {strapiGuides.map(({ node }) => {
              return (
                <li key={`guide-${node.id}`}>
                  <GuideCard data={node} pagePath={routes.app.guides.path} />
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.guideRequestContainer}>
          <p className={global.text}>
            Vous avez une idée de sélection en tête ? <br />
            Dites-le moi !
          </p>
          <LinkCta
            href="https://research.typeform.com/to/ZjaYMRQs"
            label="Proposer une idée"
            target="_blank"
            wordingClassname={styles.secondaryWordingCta}
            btnClassname={styles.secondaryBtnCta}
            wordingHoverClassname={styles.secondaryHoverWordingCta}
            btnHoverClassname={styles.secondaryHoverBtnCta}
            onClick={() => {
              sendGA("click", {
                event_label: "page guides - cta- requestGuide",
              });
            }}
          />
        </div>
        {/* <Newsletter /> */}
      </div>
    </>
  );
};

export default LesGuidesDesMarquesEthiques;
